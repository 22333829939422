import React from "react";
import ProfilePic from "../Assets/james.jpg";
import { AiFillStar } from "react-icons/ai";

const Testimonial = () => {
  return (
    <div  className="work-section-wrapper" id="testimonials">
      <div className="work-section-top">
        <p className="primary-subheading">Testimonial</p>
        <h1 style={{ margin: "0",  }} className="primary-heading">What Other Spaza Shops Are Saying</h1>
        {/* <p style={{ textAlign: "left", }} className="primary-text">
          Discover How Surfin Transformed Businesses. 
        </p> */}
      </div>
      <div style={{ maxWidth: "100%", paddingBottom: "50px",  }} className="testimonial-section-bottom ">
        <div className="card">
        <img
          src={ProfilePic}
          alt="James"
          style={{
            borderRadius: "50%",
            width: "100px",
            height: "100px",
            objectFit: "cover",
            objectPosition: "center",
            border: "3px solid #4CAF50",
            boxShadow: "0 0 10px rgba(76, 175, 80, 0.5)",
          }}
        />
        <p style={{ fontWeight: "400", fontSize: "14"}} >
          Surfin will transform my business. I will no longer worry about stock shortages or delays.
        </p>
        <div className="testimonials-stars-container">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <h2>James</h2>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
