import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import './Privacy.css'; // Import your CSS file for specific styles

const Privacy = () => {
  return (
    <>
      <Navbar />
      <div className="privacy-container"> {/* Updated class for styling */}
        <h1 className="privacy-title">Privacy Policy for Surfin</h1> {/* Updated class for styling */}
        <section className="privacy-section"> {/* Updated class for styling */}
          <h2 className="privacy-subtitle">Introduction</h2> {/* Updated class for styling */}
          <p className="privacy-text"> {/* Updated class for styling */}
            Welcome to Surfin! This privacy policy outlines how we
            collect, use, disclose, and protect your personal information in
            accordance with applicable privacy laws.
          </p>
        </section>
        <section className="privacy-section"> {/* Updated class for styling */}
          <h2 className="privacy-subtitle">Information We Collect</h2> {/* Updated class for styling */}
          <p className="privacy-text"> {/* Updated class for styling */}
            We may collect the following types of personal information:
          </p>
          <ul className="privacy-list"> {/* Updated class for styling */}
            <li>Personal identification information (Name, email address, phone number, etc.)</li>
            <li>Demographic information (age, gender, etc.)</li>
            <li>Usage data (how you use our website and services)</li>
            <li>Technical data (IP address, browser type, etc.)</li>
          </ul>
        </section>
        <section className="privacy-section"> {/* Updated class for styling */}
          <h2 className="privacy-subtitle">How We Use Your Information</h2> {/* Updated class for styling */}
          <p className="privacy-text"> {/* Updated class for styling */}
            We use the collected information for various purposes, including:
          </p>
          <ul className="privacy-list"> {/* Updated class for styling */}
            <li>To provide and maintain our services</li>
            <li>To notify you about changes to our services</li>
            <li>To provide customer support</li>
            <li>To gather analysis or valuable information to improve our services</li>
            <li>To monitor the usage of our services</li>
            <li>To detect, prevent, and address technical issues</li>
          </ul>
        </section>
        <section className="privacy-section"> {/* Updated class for styling */}
          <h2 className="privacy-subtitle">Disclosure of Your Information</h2> {/* Updated class for styling */}
          <p className="privacy-text"> {/* Updated class for styling */}
            We may disclose your personal information:
          </p>
          <ul className="privacy-list"> {/* Updated class for styling */}
            <li>To comply with a legal obligation</li>
            <li>To protect and defend the rights or property</li>
            <li>To prevent or investigate possible wrongdoing in connection with the service</li>
            <li>To protect the personal safety of users or the public</li>
            <li>To protect against legal liability</li>
          </ul>
        </section>
        <section className="privacy-section"> {/* Updated class for styling */}
          <h2 className="privacy-subtitle">Security of Your Information</h2> {/* Updated class for styling */}
          <p className="privacy-text"> {/* Updated class for styling */}
            We are committed to ensuring that your information is secure. We have implemented suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
          </p>
        </section>
        <section className="privacy-section"> {/* Updated class for styling */}
          <h2 className="privacy-subtitle">Your Data Protection Rights</h2> {/* Updated class for styling */}
          <p className="privacy-text"> {/* Updated class for styling */}
            You have the right to:
          </p>
          <ul className="privacy-list"> {/* Updated class for styling */}
            <li>Request access to your personal data</li>
            <li>Request correction of your personal data</li>
            <li>Request deletion of your personal data</li>
            <li>Object to processing of your personal data</li>
            <li>Request restriction of processing your personal data</li>
            <li>Request transfer of your personal data</li>
            <li>Withdraw your consent</li>
          </ul>
        </section>
        <section className="privacy-section"> {/* Updated class for styling */}
          <h2 className="privacy-subtitle">Contact Us</h2> {/* Updated class for styling */}
          <p className="privacy-text"> {/* Updated class for styling */}
            If you have any questions about this Privacy Policy, please contact us:
          </p>
          <ul className="privacy-list"> {/* Updated class for styling */}
            <li>By email: support@surfin.africa</li>
            <li>By visiting this page on our website: www.surfin.africa/contact</li>
            <li>By phone number: +27 123 456 789</li>
          </ul>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;