import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import './Terms.css'; // Import your CSS file for specific styles

const Terms = () => {
  return (
    <>
      <Navbar />
      <div className="terms-container"> {/* Updated class for styling */}
        <h1 className="terms-title">Terms and Conditions for Surfin</h1> {/* Updated class for styling */}
        <section className="terms-section"> {/* Updated class for styling */}
          <h2 className="terms-subtitle">Introduction</h2> {/* Updated class for styling */}
          <p className="terms-text"> {/* Updated class for styling */}
            Welcome to Surfin! These terms and conditions outline the rules and regulations for the use of our website and services.
          </p>
        </section>
        <section className="terms-section"> {/* Updated class for styling */}
          <h2 className="terms-subtitle">Acceptance of Terms</h2> {/* Updated class for styling */}
          <p className="terms-text"> {/* Updated class for styling */}
            By accessing this website, you accept these terms and conditions in full. If you disagree with any part of these terms, you must not use our website.
          </p>
        </section>
        <section className="terms-section"> {/* Updated class for styling */}
          <h2 className="terms-subtitle">Changes to Terms</h2> {/* Updated class for styling */}
          <p className="terms-text"> {/* Updated class for styling */}
            We reserve the right to modify these terms at any time. Changes will be effective immediately upon posting on this page.
          </p>
        </section>
        <section className="terms-section"> {/* Updated class for styling */}
          <h2 className="terms-subtitle">User Responsibilities</h2> {/* Updated class for styling */}
          <p className="terms-text"> {/* Updated class for styling */}
            Users are responsible for ensuring that their use of the website complies with all applicable laws and regulations.
          </p>
        </section>
        <section className="terms-section"> {/* Updated class for styling */}
          <h2 className="terms-subtitle">Limitation of Liability</h2> {/* Updated class for styling */}
          <p className="terms-text"> {/* Updated class for styling */}
            Surfin shall not be liable for any damages arising from the use of our website or services.
          </p>
        </section>
        <section className="terms-section"> {/* Updated class for styling */}
          <h2 className="terms-subtitle">Contact Us</h2> {/* Updated class for styling */}
          <p className="terms-text"> {/* Updated class for styling */}
            If you have any questions about these Terms and Conditions, please contact us:
          </p>
          <ul className="terms-list"> {/* Updated class for styling */}
            <li>By email: support@surfin.africa</li>
            <li>By visiting this page on our website: www.surfin.africa/contact</li>
            <li>By phone number: +27 123 456 789</li>
          </ul>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Terms;