import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image.png";
// import { BsFillPlayCircleFill } from "react-icons/bs";

const About = () => {
  return (
    <div className="about-section-container" id="about">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">About Surfin</p>
        <h1 className="primary-heading">
        Empowering Your Spaza Shop with{" "}
          <span className="green-text">Direct Stocking</span> and{" "}
          <span className="green-text">Easy Stock Management</span>.
        </h1>
        <p className="primary-text">
          Surfin is a digital platform designed to help spaza shop owners thrive. 
          We simplify product sourcing by connecting you directly with top FMCG suppliers and local farmers, 
          giving you access to quality products at the best prices.
        </p>
        <p className="primary-text">
        With Surfin, you can easily manage your stock, track orders, and access financial services like microcredit. 
        Our goal is to empower your business, helping you grow, serve your community better, and stay ahead in the market. 
        
        </p>
        <p className="primary-text">
          Join Surfin today and discover a smarter way to run your spaza shop.
        </p>
        <div className="work-buttons-container">
          {/* <button className="secondary-button">Learn More</button> */}
          <button className="watch-video-button">
            {/* <BsFillPlayCircleFill /> Watch Video */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
