import React from "react";
import { FiArrowRight } from "react-icons/fi";
import PickMeals from "../Assets/choose-image.png";
import ChooseMeals from "../Assets/pick-meals-image.png";
import DeliveryMeals from "../Assets/delivery-image.png";
import { Margin } from "@mui/icons-material";

const Work = () => {
  const workInfoData = [
    {
      image: PickMeals,
      title: "Browse your Stock",
      text: "Browse and Choose from our products Catalogue",
    },
    {
      image: ChooseMeals,
      title: "Place Your Order",
      text: "Place an Order on Surfin App or Whatsapp.",
    },
    {
      image: DeliveryMeals,
      title: "Get Fast & Free Delivery",
      text: "Get Fast & Free Delivery Directly to your Shop. Pay on Delivery",
    },
  ];
  return (
    <div className="work-section-wrapper" id="works">
      <div className="work-section-top  ">
        <p className="primary-subheading">Work</p>
        <h1 className="primary-heading">How It Works</h1>
        <p className="primary-text">
          Order <span className="green-text">Stock</span> in{" "}
          <span className="green-text">3 Simple Steps</span>. Get Fast and Free
          Delivery.
        </p>
      </div>
      <a href="https://surfin.flutterflow.app/" className="work-section-bottom " style={{textDecoration: "none", target: "_blank"}}>
        {workInfoData.map((data) => (
          <div className="work-section-info card " key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
        
      </a>
      <div style={{ marginTop:" 8px",  marginBottom: "0px",}} className="work-section-bottom-button">
        <a
          className="secondary-button"
          href="https://surfin.flutterflow.app/"
          target="_blank"
          rel="noreferrer"
        >
          <span className="button-content">
            Order Now
            <FiArrowRight className="button-icon" />
          </span>
        </a>
      </div>

    </div>
  );
};

export default Work;
