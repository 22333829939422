import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const NoPage = () => {
  return (
    <> <Navbar/>
      <h1>Error 404: Pages Not Found</h1>
      <Footer/>
    </>
  );
};

export default NoPage;
