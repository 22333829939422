import React from "react";
import Logo from "../Assets/Logo.svg";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
        <div className="footer-icons">
          <a href="/twitter" target="_blank" rel="noreferrer">
            <BsTwitter />
          </a>
          <a href="/linkedin" target="_blank" rel="noreferrer">
            <SiLinkedin />
          </a>
          <a href="/youtube" target="_blank" rel="noreferrer">
            <BsYoutube />
          </a>
          <a href="/facebook" target="_blank" rel="noreferrer">
            <FaFacebookF />
          </a>
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span>
            <a
              className="footer-text"
              href="https://wa.link/ghl3rj"
              target="_blank"
              rel="noreferrer"
            >
              Contact
            </a>
          </span>
          <span>
            <a
              className="footer-text"
              href="https://surfin.flutterflow.app/"
              target="_blank"
              rel="noreferrer"
            >
              About
            </a>
          </span>
          <span>
            <a
              className="footer-text"
              href="https://wa.link/ghl3rj"
              target="_blank"
              rel="noreferrer"
            >
              WhatsApp Us
            </a>
          </span>
          <span>
            <a
              className="footer-text"
              href="https://surfin.flutterflow.app/"
              target="_blank"
              rel="noreferrer"
            >
              Testimonials
            </a>
          </span>
          <span>
            <a
              className="footer-text"
              href="https://surfin.flutterflow.app/"
              target="_blank"
              rel="noreferrer"
            >
              How it Works
            </a>
          </span>
        </div>
        <div className="footer-section-columns">
          <span>
            <a
              className="footer-text"
              href="tel:+27813686509"
              target="_blank"
              rel="noreferrer"
            >
              (+27) 81 368 6509
            </a>
          </span>
          <span>
            <a
              className="footer-text"
              href="mailto:info@surfin.co.za"
              target="_blank"
              rel="noreferrer"
            >
              info@surfin.co.za
            </a>
          </span>
          <span>
            <a
              className="footer-text"
              href="/sales"
              target="_blank"
              rel="noreferrer"
            >
              sales@surfin.co.za
            </a>
          </span>
        </div>
        <div className="footer-section-columns">
          <span>
            <a
              className="footer-text"
              href="/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </span>
          <span>
            <a
              className="footer-text"
              href="/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
