import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/home-banner-image.png";
// import HuaweiBtn from "../Assets/huawei.png";
import AndroidBtn from "../Assets/android.png";
import IOSBtn from "../Assets/ios.png";
// import { FiArrowRight } from "react-icons/fi";
import CookieConsent from "react-cookie-consent";

const Home = () => {
  return (
    <div className="home-container" id="/">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div  className="home-text-section" >
          <h1 style={{ textAlign: "left"}} className="primary-heading ">
              <span className="green-text main">  <span style={{color: "#f59e0b", fontWeight:"500"}}>Browse</span> &</span><br/>
              <span className="green-text main">  <span style={{color: "#f59e0b", fontWeight:"500"}}>Order</span> Stock.</span><br/>
              <span className="green-text main"> <span style={{color: "#f59e0b", fontWeight:"500"}}>Pay</span> on delivery.</span><br/>
              <span className="green-text main"> <span style={{color: "#f59e0b", fontWeight:"500"}}>Manage</span> your Stock.</span>
          </h1>

           <div style={{ display:"flex", margin:" 14px",  marginLeft: "0",}} >
           <a
            href="https://surfin.flutterflow.app/"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className="nav-logo-container"
          >
            <img src={IOSBtn}  alt="Download on the App Store" style={{ height:"48px", marginRight:"10px", cursor: "pointer" }} />
          </a>
          <a
            href="https://surfin.flutterflow.app/"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className="nav-logo-container"
          >
            <img src={AndroidBtn}  alt="Get it on Google Play" style={{ height:"48px", marginRight:"10px", cursor: "pointer" }} />

          </a>    
            </div>

            <p className="sub-header-line-bold">
              Take your spaza shop to the next level with Surfin
            </p>

            <p class="sub-header-line primary-text-header" >
              Surfin is an App that connects you directly to top suppliers and small-scale farmers, 
              giving you access to competitive prices and fresh products.
              </p>

              <p class="sub-header-line primary-text-header">
              Manage your stock, access financial tools, Sell your products online and offer fast delivery to your customers—all in one place. 
              Join the Surfin App and transform your Spaza Shop Business.
            </p>


          {/* <p style={{ textAlign: "left"}} className="primary-text">
          Restock your shop effortlessly with Surfin! Order products in just 3 simple steps. Enjoy same day delivery and the convenience of pay on delivery. <br/> Say goodbye to stock shortages and hello to seamless restocking. Join Surfin today!
          </p> */}
          {/* <a className="secondary-button" href="https://vlaskxz0gss.typeform.com/to/LXuw89ux" target="_blank" rel="noreferrer">
            Order Now<FiArrowRight/>{" "}
          </a> */}
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
      <CookieConsent 
      debug={true}
      location="bottom"
      style={{backgroundColor: "#000", textAlign: "left"}}
      buttonStyle={{fontSize: "14px", fontWeight: "bold", borderRadius:"0.5rem"}}
      buttonText="Accept"
      // expires={3.5}
      >
        We use cookies to enhance your browsing experience and provide personalized content on our website. By clicking 'Accept' or continuing to browse, you consent to the use of cookies. You can manage your cookie preferences in the settings. For more information, please refer to our <a className="green-text" href="/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>.
      </CookieConsent>
    </div>
  );
};

export default Home;
