// import React from "react";

// const Contact = () => {
//   return (
//     <div className="contact-page-wrapper" id="contact">
//       <h1 className="primary-heading">Need any help?</h1>
//       <div className="contact-form-container">
//         {/* <input type="text" placeholder="yourmail@gmail.com" /> */}
//         {/* <button className="secondary-button"> */}
//         <a
//           className="secondary-button"
//           href="https://wa.link/ghl3rj"
//           target="_blank"
//           rel="noreferrer"
//         >
//           WhatsApp Us
//         </a>
//         {/* </button> */}
//       </div>
//     </div>
//   );
// };

// export default Contact;


import React, { useState } from "react";
import "./NewsletterSignup.css"; // Import the CSS file

const NewsletterSignup = () => {
  const [email, setEmail] = useState(""); // State to hold the email input
  const [submitted, setSubmitted] = useState(false); // State to indicate if form was submitted

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [address, setAddress] = useState('');

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleAddressSubmit = (e) => {
    e.preventDefault();
    console.log('Address submitted:', address);
  };


  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      setSubmitted(true);
      console.log("Successfully signed up with email:", email);
      setEmail(""); // Clear the input field
    } else {
      alert("Please enter a valid email address.");
    }
  };

  return (
    <div className="surfin-promo-banner">
          <h2 className="surfin-promo-title">Stay Ahead in the Kasi Economy</h2>
          <p className="surfin-promo-subtitle">Get the latest updates on exclusive deals, market trends, and new features.</p>
          <form onSubmit={handleAddressSubmit} className="surfin-promo-form">
            <div className="surfin-input-wrapper">
              {/* <MapPin className="surfin-input-icon" /> */}
              <input
                type="text"
                placeholder="Enter your Email Address"
                className="surfin-input"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <button type="submit" className="surfin-submit-btn">
              Join now
            </button>
          </form>
        </div>
  );
};

export default NewsletterSignup;

