/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Logo from "../Assets/Logo.svg";
// import { BsCart2 } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import { Link } from "react-scroll";
import { FiArrowRight } from "react-icons/fi";
import { QuestionAnswerRounded } from "@mui/icons-material";
import Home from "./header";
import About from "../Components/About";
import Work from "../Components/Work";
import Testimonial from "../Components/Testimonial";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const menuOptions = [
    // {
    //   text: "Home",
    //   icon: <HomeIcon />,
    //   Link: "/",
    // },
    // {
    //   text: "About",
    //   icon: <InfoIcon />,
    //   Link: "about",
    // },
    // {
    //   text: "How it Works",
    //   icon: <QuestionAnswerRounded />,
    //   Link: "works",
    // },
    // {
    //   text: "Testimonials",
    //   icon: <CommentRoundedIcon />,
    //   Link: "testimonials",
    // },
    // {
    //   text: "Contact",
    //   icon: <PhoneRoundedIcon />,
    //   Link: "contact",
    // },
    // {
    //   text: "Cart",
    //   icon: <ShoppingCartRoundedIcon />,
    // },
  ];
  return (
    <nav className={navbar ? "navbar active" : "navbar"}>
      <a
        href="/"
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
        className="nav-logo-container"
      >
        <img src={Logo} alt="Surfin logo" />
      </a>
      <div className="navbar-links-container">
        {/* <a href="/home" spy={true} smooth={true} offset={-100} duration={500}>
          Home
        </a>
        <a href={About} spy={true} smooth={true} offset={-100} duration={500}>
          About
        </a>
        <a href="/home" spy={true} smooth={true} offset={-100} duration={500}>
          How it Works
        </a>
        <a href="/home" spy={true} smooth={true} offset={-100} duration={500}>
          Testimonials
        </a>
        <a href="/home" spy={true} smooth={true} offset={-100} duration={500}>
          Contact
        </a> */}
        {/* <Link to="cart" spy={true} smooth={true} offset={-100} duration={500}>
          <BsCart2 className="navbar-cart-icon" />
        </Link> */}
        <a
          className="primary-button"
          href="https://surfin.flutterflow.app/"
          target="_blank"
          rel="noreferrer"
        >
          Get Started
        </a>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    to={item.link}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <div className="side-nav-button" style={{ margin: '0 16px', width: 'calc(100% - 32px)' }}>
            <a
              className="secondary-button"
              href="https://surfin.flutterflow.app/"
              target="_blank"
              rel="noreferrer"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                width: '100%',
                padding: '10px 0'
              }}
            >
              Get Started <FiArrowRight />
            </a>
          </div>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;
